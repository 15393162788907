import MDEditor from '@uiw/react-md-editor';
import React, { useMemo, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import Select from 'react-select';
import ACTIONS from '../../store/action';
import { Actions as ADMINACTIONS } from '../../store/actions/adminActions';
import { Actions as ORGANISATIONACTIONS } from '../../store/actions/organisationActions';
import { Actions as PAYMENTACTIONS } from '../../store/actions/paymentActions';
import { Checkbox, FormControlLabel, TextareaAutosize, Tooltip, Tabs, Tab, Box, Alert } from '@mui/material';
import { format } from 'date-fns';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { AddButton, CalendarBtn } from '../../styledComponents/GlobalStyle';
import { filteringTable } from '../AdminPortal/utils/adminPortalHelpers';
import { convertStringToNull, convertToTitleCase, parseDateToTz } from '../utils/GlobalFunctions';

const AdminPortalModal = ({
  toggleModal,
  adminPortalModal,
  modalData,
  modalProps,
  updateOrAddToDB,
  fullName,
  getItemsByTermsFromIndex,
  adminOrgs,
  modalAction,
  saveOrganisation,
  saveAddOnsToAllClientOrgs,
  toggleAlertMessage,
  getAllItemsFromDB,
  updateDefaultValues,
  linkStripeAccount,
  unlinkStripeAccount,
  getStripeCustomerIdForDisplay,
}) => {
  const { elements, btn, modalTitle, modalSubtitle, tableName, filters, wrapperStyle } = modalProps || {};

  const [loading, setLoading] = useState(false);
  const [editedState, setEditedState] = useState({ ...modalData });
  const [searchFilter, setSearchFilter] = useState(modalData?.filter || {});
  const [tableData, setTableData] = useState(elements?.[0].data || []);
  const [value, setValue] = useState('0');
  const [stripeCustomerId, saveStripeCustomerId] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const handleChange = (event, newValue) => {
    if (newValue === '0') {
      toggleModal(true, 'linkStripeAccount');
      setDisabled(false);
    } else {
      toggleModal(true, 'unlinkStripeAccount');
      if (!stripeCustomerId) {
        setDisabled(true);
      }
    }
    setValue(newValue);
  };

  useEffect(() => {
    if (modalAction === 'airport-table-edit' && tableName === 'airport') {
      let newEditedState = { ...editedState };
      newEditedState['prevAirportCode'] = editedState?.code;
      setEditedState(newEditedState);
    }
  }, []);

  const divStyle = {
    display: 'flex',
    width: '100%',
    margin: '0px 10px 10px 10px',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  };

  // When adding add ons to an organisation, if the organisation selected is an admin, add a checkbox to the modal
  // so the user can select if the add on is for all the organisations or just the admin one
  //eslint-disable-next-line
  const checkIfOrgSelectedIsAdmin = useMemo(() => {
    const addAddOnToOrg = modalAction === 'add-addOns-to-Organisation';
    if (!addAddOnToOrg) return null;
    else if (addAddOnToOrg) {
      const orgSelected = adminOrgs.find((x) => x.id === editedState.organisation);
      const adminOrg = orgSelected
        ? orgSelected?.id === orgSelected?.whiteLabelId || orgSelected?.id === orgSelected?.consultantId
        : null;
      if (adminOrg) {
        // Check first if there is a checkbox, if it doesn't exist add it
        if (elements[elements.length - 1]?.type !== 'checkbox') {
          elements.push({ type: 'checkbox', label: 'Add Bundle to all client organisations?', name: 'addOnsToAllClients' });
          setEditedState({ ...editedState, addOnsToAllClients: false });
        }
      } else {
        // Check first if there is a checkbox, if so remove it
        if (elements[elements.length - 1]?.type === 'checkbox') {
          elements.pop();
          setEditedState((prevState) => {
            delete prevState.addOnsToAllClients;
            return prevState;
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [editedState?.organisation]);

  if (!adminPortalModal) {
    return null;
  }

  let updateYear;

  switch (tableName) {
    case 'reports':
      updateYear = true;
      break;
    case 'organisationMetrics':
      updateYear = true;
      break;
    default:
  }

  const updateFilter = (selectedFilter, e) => {
    const newFilter = { ...searchFilter };
    const filterName = e?.target?.name || e.name;

    if (e?.action === 'clear' || !selectedFilter || e?.target?.value === '') {
      delete newFilter[filterName];
    } else if (e?.target?.type === 'text') {
      newFilter[filterName] = e?.target?.value;
    } else if (typeof selectedFilter.getMonth === 'function') {
      const objProp = Object.getOwnPropertyNames(e);
      newFilter[objProp[0]] = { ...newFilter[objProp[0]], ...e[objProp[0]] };
    } else {
      newFilter[filterName] = selectedFilter?.value || selectedFilter;
    }
    setSearchFilter(newFilter);
  };

  const updateField = (e) => {
    let newEditedState = { ...editedState };
    newEditedState[e.target.name] = e.target.value;
    setEditedState(newEditedState);
  };

  let heightSize;

  if (elements?.length < 10) {
    heightSize = 'fit-content';
  } else {
    heightSize = '80vh';
  }

  let showFullName = false;
  //Function to show which fields are required when adding a conversion factor
  let cf = (element) => {
    if (tableName === 'conversionFactors' && ['year', 'subentity', 'metric', 'scope'].includes(element?.name)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className='modal' style={{ display: 'block', alignItems: 'center', background: 'rgba(0,0,0,.5)' }}>
      <div className='modal-dialog' style={{ height: '80vh', fontSize: '12px' }}>
        <div
          className='modal-content'
          style={{ margin: '70px auto', width: 'auto', height: 'auto', background: 'transparent' }}>
          <div className='modal-header'>
            <button type='button' onClick={() => toggleModal(false, null)} className='close'>
              x
            </button>
            <h4 className='modal-title'>{modalTitle}</h4>
          </div>
          <div className='modal-body' style={{ display: modalSubtitle ? 'block' : 'none', background: 'white' }}>
            {modalSubtitle}
          </div>
          <div className='modal-body' style={{ display: filters ? 'flex' : 'none', background: 'white' }}>
            {filters?.map((filter, index) => {
              return (
                <div style={{ width: '25%', marginRight: 20 }} key={index}>
                  {filter?.type === 'select' && (
                    <Select
                      options={filter?.options}
                      //placeholder={filter?.placeholder}
                      name={filter?.name}
                      isClearable={filter?.isClearable}
                      onChange={(selected) => {
                        const newFilter = { ...searchFilter } || {};
                        if (selected) {
                          newFilter[filter?.name] = selected?.value;
                        } else {
                          delete newFilter[filter?.name];
                        }
                        setSearchFilter(newFilter);
                        setTableData(filteringTable(elements?.[0]?.data, newFilter));
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div id='Background' style={{ background: 'transparent', height: '80vh', overflow: 'auto' }}>
            <div
              className='modal-body'
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflow: 'visible',
                background: 'white',
                borderBottomRightRadius: '6px',
                borderBottomLeftRadius: '6px',
                ...wrapperStyle,
                // height: heightSize,
                //overflowY: tableName === 'usageTypeDetails' ? 'visible' : 'auto',
                // overflowX: 'hidden',
              }}>
              {tableName === 'conversionFactors' && (
                <span style={{ color: 'red' }}>
                  * Fields in red are required for the Conversion Factors to function properly
                </span>
              )}
              {elements?.map((e, index) => {
                if (['userId', 'creator'].includes(e.name)) {
                  showFullName = true;
                }
                if (e?.component === 'tab') {
                  return (
                    <div style={{ width: '100%', marginBottom: '10px', borderBottom: '1px', borderColor: 'grey' }}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} centered>
                          <Tab style={{ padding: '10px 15px' }} label={e.labels[0]} value={'0'} />
                          <Tab style={{ padding: '10px 15px' }} label={e.labels[1]} value={'1'} />
                        </Tabs>
                      </Box>
                    </div>
                  );
                }
                if (e?.component === 'select') {
                  return (
                    <div key={index} className='form-group' style={{ width: '100%', marginTop: index == 0 ? 20 : 0 }}>
                      <div>
                        <label style={{ color: cf(e) ? 'red' : 'inherit' }}>{e.label}</label>
                      </div>
                      <div style={{ width: 'auto' }}>
                        <Select
                          key={index}
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          maxMenuHeight={200}
                          menuPlacement={elements?.length > 8 && index >= elements?.length - 2 ? 'top' : 'auto'}
                          options={e?.options}
                          //placeholder={e?.placeholder}
                          id={'select-' + e?.name}
                          name={e?.name}
                          isClearable={e?.isClearable}
                          isSearchable={e?.isSearchable}
                          isMulti={e?.isMulti}
                          onChange={async (selected, event) => {
                            const newEditedState = { ...editedState };
                            if (!selected) {
                              newEditedState[e?.name] = null;
                            } else if (e.isMulti) {
                              newEditedState[e?.name] = selected?.map((x) => x.value);
                            } else if (Object.keys(searchFilter).length > 0) {
                              updateFilter(selected, event);
                            } else {
                              newEditedState[e?.name] = selected?.value;
                            }
                            setEditedState(newEditedState);
                            if (tableName === 'usageTypeDetails' && e.label === 'Type') {
                              updateDefaultValues(selected?.value);
                              toggleModal(false, null);
                              modalTitle === 'Edit Usage Type Detail'
                                ? toggleModal(true, 'usageTypeDetails-table-edit', null, selected?.value)
                                : toggleModal(true, 'usageTypeDetails-table-add', null, false);
                            }
                            if (modalAction === 'unlinkStripeAccount' && e?.name === 'organisationID') {
                              const id = await getStripeCustomerIdForDisplay(selected?.value);
                              saveStripeCustomerId(id);
                              if (!id) {
                                setDisabled(true);
                              } else {
                                setDisabled(false);
                              }
                            }
                          }}
                          value={
                            e.isMulti
                              ? editedState[e.name]?.map((id) => e?.options.find((x) => x.value == id))
                              : searchFilter && Object.keys(searchFilter)?.length > 0
                              ? e?.options?.filter((elem) => elem.value == searchFilter[e.name]) || null
                              : e?.options?.filter((elem) => elem.value == editedState[e.name]) || null
                          }
                        />
                      </div>
                    </div>
                  );
                }

                if (e?.type === 'textarea') {
                  return (
                    <div key={index} style={{ width: '100%' }}>
                      {/* -webkit-fill-available */}
                      <div>
                        <label>{e.label}</label>
                      </div>
                      <TextareaAutosize
                        maxRows={20}
                        aria-label='maximum height'
                        className='form-control'
                        name={e?.name}
                        //placeholder={e?.placeholder}
                        style={{ resize: 'none' }}
                        onChange={updateField}
                        value={editedState[e?.name] === 'null' ? null : editedState[e?.name]}
                      />
                    </div>
                  );
                }

                if (e?.component === 'StripeCustomerId') {
                  return (
                    <div key={index} style={{ width: '100%' }}>
                      <div>
                        <label>{e?.label}</label>
                      </div>
                      <input
                        className='form-control'
                        value={stripeCustomerId || 'No Customer Id found'}
                        name={e?.name}
                        readOnly={true}></input>
                    </div>
                  );
                }

                if (e?.component === 'markdownText') {
                  return (
                    <div key={index} style={{ width: '100%' }}>
                      <div>
                        <label>{e.label}</label>
                      </div>
                      <div className='container' style={{ width: '95%' }}>
                        <MDEditor
                          value={editedState[e?.name]}
                          onChange={(x) => {
                            const newEditedState = { ...editedState };
                            newEditedState[e?.name] = x;
                            setEditedState(newEditedState);
                          }}
                          hideToolbar={true}
                        />
                      </div>
                    </div>
                  );
                }

                if (e?.component === 'datePicker') {
                  return (
                    <div key={index} className='form-group' style={{ width: '100%' }}>
                      <div>
                        <label>{e.label}</label>
                      </div>
                      <label className='input-group date datepicker'>
                        <DatePicker
                          showMonthDropdown
                          showYearDropdown
                          dateFormat='dd/MM/yyyy'
                          selected={
                            editedState[e?.name] === 'null' || !editedState[e?.name] ? null : new Date(editedState[e?.name])
                          }
                          onChange={(date) => {
                            const newEditedState = { ...editedState };
                            newEditedState[e?.name] = format(date, 'yyyy-MM-dd');
                            if (updateYear && e.name == 'startDate') {
                              const splitStartDate = newEditedState[e?.name].split('-');
                              let year = +splitStartDate[0];
                              const month = +splitStartDate[1];
                              if (month >= 7) newEditedState['year'] = String(year + 1);
                              else newEditedState['year'] = String(year);
                            }
                            setEditedState(newEditedState);
                          }}
                          selectsStart
                          className='form-control startDate'
                          disabled={e?.disabled ? e?.disabled : false}
                          maxDate={editedState[e.endDate]}
                          popperPlacement='bottom-end'
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: '77px, 0px',
                            },
                          }}
                        />
                        <span className='input-group-btn'>
                          <CalendarBtn className='btn btn-primary date-set'>
                            {' '}
                            <i className='fa fa-calendar'></i>{' '}
                          </CalendarBtn>
                        </span>
                      </label>
                    </div>
                  );
                }

                if (e?.component === 'usageSearch_datePicker') {
                  return (
                    <div key={index} style={divStyle}>
                      <label style={{ fontSize: 16, marginBottom: 0 }}>
                        {e.type.charAt(0).toUpperCase() + e.type.slice(1)}
                      </label>
                      <div style={{ width: '80%', display: 'flex', justifyContent: 'space-between', zIndex: 0 }}>
                        <div style={{ width: '40%' }}>
                          <label className='input-group date datepicker'>
                            <DatePicker
                              showMonthDropdown
                              name={e.type}
                              showYearDropdown
                              yearDropdownItemNumber={5}
                              scrollableYearDropdown
                              dateFormat='dd/MM/yyyy'
                              className='form-control startDate'
                              popperPlacement='bottom-end'
                              maxDate={new Date()}
                              selected={
                                (searchFilter?.[e.type]?.['gte'] &&
                                  parseDateToTz(searchFilter?.[e.type]?.['gte'], 'yyyy-MM-dd')) ||
                                null
                              }
                              isClearable={true}
                              placeholderText='From'
                              onChange={(date) => {
                                if (!date) updateFilter(null, { name: e.type });
                                else updateFilter(date, { [e.type]: { gte: format(date, 'yyyy-MM-dd') } });
                              }}
                              popperModifiers={{ offset: { enabled: true, offset: '77px, 0px' } }}
                            />
                            <span className='input-group-btn'>
                              <CalendarBtn className='btn btn-primary date-set'>
                                <i className='fa fa-calendar' />
                              </CalendarBtn>
                            </span>
                          </label>
                        </div>
                        <div style={{ width: '40%' }}>
                          <label className='input-group date datepicker'>
                            <DatePicker
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              dateFormat='dd/MM/yyyy'
                              className='form-control startDate'
                              popperPlacement='bottom-end'
                              yearDropdownItemNumber={5}
                              isClearable={true}
                              maxDate={new Date()}
                              selected={
                                (searchFilter?.[e.type]?.['lte'] &&
                                  parseDateToTz(searchFilter?.[e.type]?.['lte'], 'yyyy-MM-dd')) ||
                                null
                              }
                              placeholderText='To'
                              onChange={(date) => {
                                if (!date) updateFilter(null, { name: e.type });
                                else updateFilter(date, { [e.type]: { lte: format(date, 'yyyy-MM-dd') } });
                              }}
                              popperModifiers={{ offset: { enabled: true, offset: '77px, 0px' } }}
                            />
                            <span className='input-group-btn'>
                              <CalendarBtn className='btn btn-primary date-set'>
                                <i className='fa fa-calendar' />
                              </CalendarBtn>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                }

                if (e?.type === 'table') {
                  const _headerRenderer = ({ label }) => {
                    return (
                      <Tooltip title={<span style={{ fontSize: 12 }}>{convertToTitleCase(label)}</span>}>
                        <div style={{ cursor: 'pointer' }} className='admin_portal_cellData_description'>
                          {convertToTitleCase(label)}
                        </div>
                      </Tooltip>
                    );
                  };

                  const _formatCellValue = ({ cellData, rowData, dataKey }) => {
                    const stateVariables = modalData?.stateVariables;
                    let value = convertStringToNull(cellData);
                    let property = 'name';
                    let stateProperty = dataKey;
                    if (['organisation'].includes(dataKey)) {
                      stateProperty = 'adminOrgs';
                    } else if (['subentity'].includes(dataKey)) {
                      stateProperty = 'subentities';
                    } else if (['price'].includes(dataKey)) {
                      property = 'priceType';
                    }

                    value = value && _?.find(stateVariables?.[stateProperty], { id: cellData })?.[property];

                    if (!value) {
                      value = convertStringToNull(cellData);
                    }

                    return (
                      <Tooltip title={<span style={{ fontSize: 12 }}>{convertToTitleCase(value)}</span>}>
                        <div style={{ cursor: 'pointer' }} className='admin_portal_cellData_description'>
                          {value}
                        </div>
                      </Tooltip>
                    );
                  };

                  return (
                    <div key={index} style={{ ...divStyle, height: '50vh', margin: 0, overflowX: 'auto' }}>
                      <AutoSizer>
                        {({ width, height }) => (
                          <Table
                            width={width + 800}
                            height={800}
                            rowHeight={100}
                            headerHeight={60}
                            rowStyle={{ textAlign: 'center' }}
                            rowCount={tableData && tableData.length}
                            rowGetter={({ index }) => {
                              return tableData?.[index];
                            }}>
                            {e?.columns?.map((column, index) => (
                              <Column
                                key={index}
                                label={column?.label || column}
                                dataKey={column?.key || column}
                                width={200}
                                headerRenderer={_headerRenderer}
                                cellRenderer={_formatCellValue}
                              />
                            ))}
                          </Table>
                        )}
                      </AutoSizer>
                    </div>
                  );
                }

                if (e?.type === 'checkbox') {
                  return (
                    <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={e?.label}
                        name={e?.name}
                        onChange={(e) => {
                          const newEditedState = { ...editedState };
                          newEditedState[e?.target?.name] = e?.target?.checked;
                          setEditedState(newEditedState);
                        }}
                        checked={editedState?.[e?.name]}
                      />
                    </div>
                  );
                }

                if (e?.type === 'warning') {
                  return (
                    <div key={index} style={{ width: '100%' }}>
                      <Alert severity='warning' icon={false} style={{ color: 'red', fontSize: '12px' }}>
                        {e.label}
                      </Alert>
                    </div>
                  );
                }

                return (
                  <div key={index} style={{ marginBottom: 15, width: '100%' }}>
                    <div style={{ width: 'auto', ...e?.style }}>
                      <label>{e.label}</label>
                    </div>
                    {e.onlyNumbers ? (
                      <NumberFormat
                        thousandSeparator={false}
                        className='form-control'
                        inputMode='numeric'
                        onChange={updateField}
                        defaultValue={editedState[e?.name]}
                        name={e?.name}
                        disabled={e?.disabled ? e?.disabled : false}
                        type='text'
                      />
                    ) : (
                      <input
                        type={e?.type}
                        className='form-control'
                        name={e?.name}
                        //placeholder={e?.placeholder}
                        style={{ width: 'inherit' }}
                        onChange={updateField}
                        disabled={e?.disabled ? e?.disabled : false}
                        value={showFullName ? fullName : editedState[e?.name]}
                      />
                    )}
                  </div>
                );
              })}
              <div style={{ display: btn ? 'block' : 'none' }}>
                <AddButton
                  className='btn btn-success'
                  disabled={loading || disabled}
                  id={btn?.title.includes('Add') ? 'add-modal-btn' : 'edit-modal-btn'}
                  style={btn?.style}
                  type={btn?.type}
                  onClick={async () => {
                    setLoading(true);
                    try {
                      if (tableName === 'conversionFactors') {
                        if (!editedState.year || !editedState.subentity || !editedState.metric || !editedState.scope) {
                          toggleAlertMessage(true, 'Year, Subentity, Metric and Scope are required fields');
                          setLoading(false);
                          return;
                        } else {
                          await updateOrAddToDB(tableName, [editedState]);
                        }
                      } else if (modalData?.type === 'usageData-bulk_search') {
                        await getItemsByTermsFromIndex(tableName, searchFilter, 1, modalData?.tableSize);
                        toggleModal(false);
                      } else if (modalAction === 'add-addOns-to-Organisation') {
                        // Save add on to an organisation
                        const orgSelected = adminOrgs.find((x) => x.id === editedState.organisation);
                        const { id: addOnId } = editedState || {};
                        if (editedState.addOnsToAllClients) {
                          // Add the Add-On to all the Consultant or White Label Admin organisations
                          await saveAddOnsToAllClientOrgs(orgSelected, addOnId);
                        } else {
                          // Save Add-On to the organisation selected
                          if (orgSelected.addOns) {
                            // Check if the add on is already added
                            if (orgSelected.addOns.includes(addOnId)) {
                              toggleAlertMessage(true, 'Add-On already added to this organisation');
                            } else {
                              orgSelected.addOns.push(addOnId);
                            }
                          } else {
                            // If the organisation doesn't have add ons, create the array and add the new one
                            orgSelected.addOns = [addOnId];
                          }
                          await saveOrganisation(orgSelected);
                        }
                      } else if (modalAction === 'linkStripeAccount') {
                        linkStripeAccount(editedState.stripeAccountId, editedState.organisationId);
                      } else if (modalAction === 'unlinkStripeAccount') {
                        unlinkStripeAccount(stripeCustomerId, editedState.organisationID, editedState.subscriptionEndDate);
                      } else {
                        //Remove searchableFields before saving data to database
                        for (let key in editedState) {
                          // Some fields include _ in the name, but should not be removed
                          const doNotRemoveField = ['calc_market', 'scope_3_cat', 'treat_scope_3'];
                          if (key.indexOf('_') !== -1 && !doNotRemoveField.includes(key)) {
                            delete editedState[key];
                          }
                        }
                        await updateOrAddToDB(tableName, [editedState]);
                        toggleModal(false);
                        getAllItemsFromDB(tableName);
                      }
                    } catch (err) {
                      console.log(err);
                    } finally {
                      setLoading(false);
                      setDisabled(false);
                    }
                  }}>
                  {loading && <i className='fa fa-spin fa-spinner' style={{ marginRight: 5 }} />}
                  {btn?.title}
                </AddButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { firstName, lastName } = state.profile?.details || {};
  const fullName = firstName + ' ' + lastName;
  return {
    adminOrgs: state.admin?.organisations,
    modalAction: state.admin?.modal?.modal,
    adminPortalModal: state.admin?.modal?.adminPortalModal,
    modalData: state.admin?.modal?.item,
    modalProps: state.admin?.modal?.modalProps,
    fullName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (status, action) => dispatch(ADMINACTIONS.toggleModal(status, action)),
  updateOrAddToDB: (table, item) => dispatch(ADMINACTIONS.updateOrAddToDB(table, item)),
  getItemsByTermsFromIndex: (index, filter, page, size) =>
    dispatch(ADMINACTIONS.getItemsByTermsFromIndex(index, filter, page, size)),
  saveOrganisation: (organisation, history) => dispatch(ORGANISATIONACTIONS.saveOrganisation(organisation, history)),
  saveAddOnsToAllClientOrgs: (organisation, addOnId) =>
    dispatch(ORGANISATIONACTIONS.saveAddOnsToAllClientOrgs(organisation, addOnId)),
  toggleAlertMessage: (status, message) => dispatch(ACTIONS.toggleAlertMessage(status, message)),
  getAllItemsFromDB: (table) => dispatch(ADMINACTIONS.getAllItemsFromDB(table)),
  updateDefaultValues: (type) => dispatch(ADMINACTIONS.updateDefaultValues(type)),
  linkStripeAccount: (stripeAccountId, organisationId) =>
    dispatch(ADMINACTIONS.linkStripeAccount(stripeAccountId, organisationId)),
  unlinkStripeAccount: (stripeAccountId, organisationId, subEndDate) =>
    dispatch(ADMINACTIONS.unlinkStripeAccount(stripeAccountId, organisationId, subEndDate)),
  getStripeCustomerIdForDisplay: (orgId) => dispatch(PAYMENTACTIONS.getStripeCustomerIdForDisplay(orgId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPortalModal);
