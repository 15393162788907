import { AddCircleOutline, Delete, Edit, Link, Visibility } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import cleanDeep from 'clean-deep';
import { format } from 'date-fns';
import { parseFromTimeZone } from 'date-fns-timezone';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import {
  RemoveTags,
  convertStringToNull,
  convertToTitleCase,
  isNumericId,
  isValidDate,
  uuidValidateV4,
} from '../../utils/GlobalFunctions.js';

// Some values we don't want to validate as they are not ids or uuids
const titleCasedValue = (dataKey, type, table) => {
  if (type === 'titleCase' && dataKey) {
    // Values we don't want to convert to title case
    const numericValues = ['scope', 'co2e', 'td', 'name', 'factor'];
    const doNotTitleCase = [
      'id',
      'metric',
      'subentity',
      'ratio',
      'email',
      'org',
      'country',
      'category',
      'iso',
      'code',
      'factor',
      'source',
      'type',
      'symbol',
      'description',
    ];
    return numericValues.concat(doNotTitleCase).some((x) => dataKey?.toLowerCase().includes(x));
  } else {
    return false;
  }
};

// Filters the data based on the filters object
export function filteringTable(data, filters) {
  if (Object.keys(cleanDeep(filters)).length === 0) {
    // No filters provided, return all data
    return data;
  }

  let check = {};

  Object.keys(filters).forEach((key) => {
    if (['addOns'].includes(key)) {
      // AddOns is stored as an Array of strings, so we need to check if the value is included in the Array
      check[key] = (object, value) => object[key]?.some((x) => x === value);
    } else {
      check[key] = (object, value) => {
        if (typeof object[key] === 'object') {
          return object[key]?.includes(value.toLowerCase());
        } else {
          return object[key]?.toLowerCase()?.includes(value.toLowerCase());
        }
      };
    }
  });

  return data.filter((object) => {
    const filterResults = Object.keys(filters).map((key) => {
      return check[key](object, filters[key]);
    });

    return filterResults.every((v) => v === true);
  });
}

// Formats the way the user can interact with the table
export const userActions = ({
  rowData,
  table,
  tableActions,
  toggleModal,
  history,
  dataKey,
  label,
  getItemsByTermsFromIndex,
  stateVariables,
  searchableFields,
}) => {
  let optionType;
  if (table === 'usageTypes') {
    // Need to get the type from the url path, for this specific table
    optionType = window.location.pathname.split('/')[2]?.replace('Type', '');
  }

  if (dataKey === 'viewData') {
    // When we want to display the data from another table, just add a view button, when clicked it opens a modal with the data
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Tooltip title={<p style={{ fontSize: 12, marginBottom: 0 }}>{`View ${label}`}</p>}>
          <Visibility
            sx={{ cursor: 'pointer' }}
            onClick={async () => {
              if (label === 'Conversion Factors') {
                // Fetch Conversion Factors based on addOns id
                await getItemsByTermsFromIndex('conversionFactors', { addOns: rowData.id }, 1, 1000);
              }
              toggleModal(true, `show-${table}-${_.camelCase(label)}`, { ...rowData, stateVariables: stateVariables });
            }}
          />
        </Tooltip>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {tableActions?.map((action, index) => {
        const label = action?.label || action;
        const userAction = action?.action || action;
        const tooltip = action?.tooltip;
        const modalAction = action?.modalAction;
        return (
          <div key={index} style={{ padding: 7 }}>
            {userAction === 'addTo' && (
              <Tooltip title={<p style={{ fontSize: 12, marginBottom: 0 }}>{label}</p>}>
                <AddCircleOutline
                  sx={{ cursor: 'pointer' }}
                  onClick={() => toggleModal(true, `add-${table}-to-Organisation`, rowData)}
                />
              </Tooltip>
            )}
            {userAction === 'viewOrg' && (
              <Tooltip title={<p style={{ fontSize: 12, marginBottom: 0 }}>{tooltip || 'View Organisation'}</p>}>
                <Visibility
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    let link = `/organisations/${rowData.id}`;
                    if (table === 'benchmark') {
                      link = `/organisations/${rowData.org}`;
                    } else if (table === 'organisationMetrics') {
                      link = `/organisations/${rowData.organisationId}`;
                    }
                    history.push(link);
                  }}
                />
              </Tooltip>
            )}
            {userAction === 'link' && (
              <Tooltip title={<p style={{ fontSize: 12, marginBottom: 0 }}>{`Go to ${convertToTitleCase(table)}`}</p>}>
                <Link
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    let link;
                    if (table === 'reports') {
                      link = `/report/${rowData.organisationId}/${rowData.id}`;
                    }
                    history.push(link);
                  }}
                />
              </Tooltip>
            )}
            {userAction === 'edit' && (
              <Tooltip title={<p style={{ fontSize: 12, marginBottom: 0 }}>{`Edit ${convertToTitleCase(table)}`}</p>}>
                <Edit
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    toggleModal(true, `${optionType || table}-table-edit`, rowData);
                  }}
                />
              </Tooltip>
            )}
            {userAction === 'delete' && (
              <Tooltip title={<p style={{ fontSize: 12, marginBottom: 0 }}>{`Delete ${convertToTitleCase(table)}`}</p>}>
                <Delete
                  sx={{ cursor: 'pointer' }}
                  onClick={() => toggleModal(true, `${optionType || table}-table-delete`, rowData, false)}
                />
              </Tooltip>
            )}
            {userAction === 'recalculate' && (
              <Tooltip
                title={<p style={{ fontSize: 12, marginBottom: 0 }}>{`Recalculate by ${convertToTitleCase(table)}`}</p>}>
                <i
                  id='recalcOrgUsage'
                  className='fa fa-refresh'
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleModal(true, 'orgs-table-recalcOrgUsage', rowData)}
                />
              </Tooltip>
            )}
            {userAction === 'clearCache' && (
              <Tooltip title={<p style={{ fontSize: 12, marginBottom: 0 }}>{tooltip}</p>}>
                <i
                  id='recalcOrgUsage'
                  className='fa fa-refresh'
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleModal(true, modalAction, rowData)}
                />
              </Tooltip>
            )}
          </div>
        );
      })}
    </div>
  );
};

// Formats the way the data is displayed in the table
export const formatCellValue = ({
  cellData,
  rowData,
  dataKey,
  table,
  stateVariables,
  tableActions,
  history,
  toggleModal,
  label,
  getItemsByTermsFromIndex,
  searchableFields,
}) => {
  // Check for 'null' values
  let value = convertStringToNull(cellData);
  let stateProperty = dataKey;
  let property = 'name';
  let searchUserFirstAndLastName = false;

  const searchForOrg = ['whiteLabelAdmin', 'consultantAdmin', 'organisation', 'org', 'organisationId', 'owner'];
  const searchForMetric = ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'];
  const isDateValue = isValidDate(value);

  // Some values are stored as ID's, so we need to get the name from the stateVariables
  if (!isDateValue) {
    //  Value is not a date, check if it's an ID
    if (searchForOrg.includes(dataKey)) {
      stateProperty = 'adminOrgs';
    } else if (searchForMetric.includes(dataKey)) {
      stateProperty = 'metric';
    } else if (['parent', 'industry'].includes(dataKey)) {
      stateProperty = 'industries';
    } else if (['source'].includes(dataKey)) {
      stateProperty = 'electricitySources';
      property = 'source';
    } else if (['subentity', 'subentityId'].includes(dataKey)) {
      stateProperty = 'subentities';
    } else if (['entity', 'entityId'].includes(dataKey)) {
      stateProperty = 'entities';
    } else if (['country'].includes(dataKey)) {
      stateProperty = 'countries';
    } else if (['facilityTypeId'].includes(dataKey)) {
      stateProperty = 'facilityTypes';
    } else if (['default'].includes(dataKey)) {
      stateProperty = 'usageTypes';
    } else if (['userPermissions', 'addOns'].includes(table)) {
      stateProperty = 'profile';
      searchUserFirstAndLastName = true;
    }
    value = value && _?.find(stateVariables?.[stateProperty], { id: cellData })?.[property];
  }

  // For tables we need to convert user id to user first and last name
  if (['creator', 'user'].includes(dataKey) && searchUserFirstAndLastName) {
    let user = stateVariables?.[stateProperty]?.find((x) => x.id === cellData);
    if (user === undefined) {
      user = stateVariables?.[stateProperty]?.find((x) => x.cognitoId === cellData);
    }
    value = user ? `${user?.firstName} ${user?.lastName}` : '';
  }

  // Value is still undefined, so we just display the cellData
  if (!value) {
    value = convertStringToNull(cellData);
  }

  // Date Values needs to be parsed differently and show the date in a different format
  if (isDateValue) {
    value = format(parseFromTimeZone(new Date(value), { timeZone: 'Europe/London' }), 'dd/MM/yyyy');
  }

  // Handle true or false values
  if (value === true || value === false) {
    value = value.toString();
  }

  if (['price'].includes(dataKey)) {
    value = stateVariables?.['price']?.find((x) => x.id === cellData)?.priceType;
  }

  // Some values are Arrays of data, so we need to map through them and display each item in a new line
  if (Array.isArray(value)) {
    cellData.forEach((x) => {
      // Check if it's an array of ID's
      if (isNumericId(x) || uuidValidateV4(x)) {
        // Turn it into an Array of Names
        if (table === 'subentity') {
          if (dataKey === 'entity') {
            stateProperty = 'entities';
          } else if (dataKey === 'metric') {
            stateProperty = 'metric';
          }
        }
        value = value && cellData?.map((item) => _.find(stateVariables?.[stateProperty], { id: item })?.name);
      } else {
        value = value && cellData;
      }
    });
  }

  // Some data is stored in markdown format, so we need to parse it
  if (['description'].includes(dataKey) && ['entity', 'usageTypes'].includes(table)) {
    return (
      <div>
        <Tooltip
          sx={{ fontSize: 10 }}
          title={
            <ReactMarkdown remarkPlugins={[gfm]} className='admin_portal_entities_description_tooltip' children={cellData} />
          }>
          <div className='admin_portal_cellData_description' style={{ cursor: 'pointer' }}>
            <ReactMarkdown remarkPlugins={[gfm]} children={cellData} />
          </div>
        </Tooltip>
      </div>
    );
  }

  if (['subentity'].includes(dataKey) && ['usageTypeDetails'].includes(table)) {
    if (cellData === null || cellData === 'null' || cellData === undefined) {
      return (
        <div>
          <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>All</p>}>
            <div style={{ textAlign: 'center', overflowX: 'auto', maxHeight: '100px' }}>All</div>
          </Tooltip>
        </div>
      );
    }
  }

  if (table && !['actions', 'viewData', 'organisations', 'conversionFactors'].includes(dataKey)) {
    if (cellData === null || cellData === 'null' || cellData === undefined) {
      return (
        <div>
          <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>{'<null>'}</p>}>
            <div style={{ textAlign: 'center', overflowX: 'auto', maxHeight: '100px', opacity: '40%' }}>{'<null>'}</div>
          </Tooltip>
        </div>
      );
    }
  }
  if (table === 'stripe' && dataKey === 'id') {
    return (
      <div>
        <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>{value}</p>}>
          <div style={{ textAlign: 'center', overflowX: 'auto', maxHeight: '100px' }}>
            {stateVariables?.['adminOrgs']?.find((o) => o.id === value)?.name || '<null>'}
          </div>
        </Tooltip>
      </div>
    );
  }

  // Values that should not be converted to title case
  const notTitleCased = titleCasedValue(dataKey, 'titleCase');
  if (!notTitleCased) {
    value = convertToTitleCase(value);
  }

  if (['actions', 'viewData'].some((x) => x === dataKey)) {
    return userActions({
      rowData,
      table,
      tableActions,
      toggleModal,
      history,
      dataKey,
      label,
      getItemsByTermsFromIndex,
      stateVariables,
      searchableFields,
    });
  } else {
    return (
      <div
        style={{ cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis' }}
        className='admin_portal_cellData_description'>
        {Array.isArray(value) ? (
          value?.map((item) => (
            <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>{item}</p>}>
              <div style={{ textAlign: 'center', overflowX: 'auto', maxHeight: '100px' }}>{item}</div>
            </Tooltip>
          ))
        ) : (
          <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>{value}</p>}>
            <div style={{ textAlign: 'center', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              {value}
            </div>
          </Tooltip>
        )}
      </div>
    );
  }
};

// Formats the way the column headers are displayed in the table
export const formatHeaderValue = ({ label }) => {
  return (
    <div>
      <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>{convertToTitleCase(label)}</p>}>
        <div style={{ cursor: 'pointer' }}>{label}</div>
      </Tooltip>
    </div>
  );
};

// Gets all the Details for each Table: columns to show/hide, actions to perform, sortIndex and tableName
export function getTableDetails(table, profile, stateVariables) {
  // We need the profile details to know what columns to show/hide, depending on the user
  const { canEditAdminPortal } = profile || {};
  const usageTypes = [
    'seatType',
    'vehicleType',
    'fuelType',
    'productionType',
    'loadType',
    'wasteType',
    'priceType',
    'originlocation',
    'finallocation',
    'factorprovider',
  ];

  let columns = []; // The columns to show on the Admin Table
  let columnsToHide = []; // The columns to hide on the Admin Table, for different users
  let actions = []; // Depending on the table, the actions that can be performed on the table: (edit, delete, etc)
  let sortIndex = 'name'; // The default sortIndex for the table
  let fetchDataFromOtherTables = []; // Some tables need to fetch data from other DB tables in order to show the data
  let tableName = table; // Some table names are different from the url path  -> we need to correct them
  let tableFilters = []; //  Each table can have their own filters
  let tableData = []; // Sometimes we migth not need to fetch the data from the DB, we can just use the data from the state
  let searchableFields = []; // Some tables can be searched by different fields
  let defaultStateValue = {}; // Default State when adding new item

  // Handle all usageTypes in one
  if (usageTypes.some((x) => x === table)) {
    tableName = 'usageTypes';
  }

  switch (tableName) {
    case 'profiles':
      columns = ['id', 'firstName', 'lastName', 'email', 'whiteLabelAdmin', 'consultantAdmin', 'registrationDate'];
      actions = ['edit'];
      sortIndex = 'firstName';
      tableName = 'profile';
      searchableFields = ['firstName', 'lastName', 'id', 'email'];
      break;
    case 'entities':
      columns = [
        'id',
        'name',
        'metric',
        'description',
        { label: 'Scope 3', key: 'scope_3_cat' },
        { label: 'treatAllAsScope3', key: 'treat_scope_3' },
        'secr',
      ];
      tableName = 'entity';
      actions = ['edit', 'delete', 'downloadFile', 'add'];
      searchableFields = ['id', 'name', 'metric'];
      break;
    case 'industries':
      columns = [
        'id',
        'name',
        { label: 'ISIC Code', key: 'code' },
        'parent',
        'uid',
        { label: 'Description of Non-Standard Ratio', key: 'descriptionOfNonStandardRatio' },
        { label: 'intensityRatioMetric1Id', key: 'intensityRatioMetric1ID' },
        { label: 'intensityRatioMetric2Id', key: 'intensityRatioMetric2ID' },
        { label: 'intensityRatioMetric3Id', key: 'intensityRatioMetric3ID' },
        'include',
      ];
      actions = ['edit', 'delete', 'add'];
      tableFilters = [
        { label: 'Itensity Ratio Metric 1', options: 'metric', name: 'intensityRatioMetric1ID' },
        { label: 'Itensity Ratio Metric 2', options: 'metric', name: 'intensityRatioMetric2ID' },
        { label: 'Itensity Ratio Metric 3', options: 'metric', name: 'intensityRatioMetric3ID' },
      ];
      searchableFields = ['name', 'id'];
      break;
    case 'industrySubcategory':
      columns = ['id', 'industry', 'name'];
      actions = ['edit', 'delete', 'add'];
      tableFilters = [{ label: 'Industries', options: 'industries', name: 'industry' }];
      fetchDataFromOtherTables = ['industries'];
      searchableFields = ['id', 'name'];
      break;
    case 'userPermissions':
      columns = ['id', { label: 'Organisation', key: 'org' }, 'user', 'email'];
      fetchDataFromOtherTables = ['profile'];
      tableFilters = [{ label: 'Organisation', options: 'adminOrgs', name: 'org' }];
      searchableFields = ['id', 'user', 'email'];
      break;
    case 'reports':
      columns = [
        'id',
        'name',
        { label: 'Organisation', key: 'organisationId' },
        'startDate',
        'endDate',
        'year',
        'factorYear',
        { label: 'Created At', key: 'created' },
        'status',
      ];
      tableFilters = [{ label: 'Organisation', options: 'adminOrgs', name: 'organisationId' }];
      sortIndex = 'organisationId';
      actions = [
        'link',
        'edit',
        'delete',
        { action: 'clearCache', tooltip: 'Clear Report Cache', modalAction: 'reports-table-cache-delete' },
      ];
      searchableFields = ['name', 'id', 'organisationId', 'description', 'year'];
      break;
    case 'seaDistances':
      columns = [
        'id',
        'originCountry',
        { label: 'Origin Country Latitude', key: 'originLat' },
        { label: 'Origin Country Longitude', key: 'originLon' },
        'originPortName',
        'originPortCode',
        { label: 'Origin Port Long Code', key: 'originLongCode' },
        'destinationCountry',
        { label: 'Destination Country Latitude', key: 'destinationLat' },
        { label: 'Destination Country Longitude', key: 'destinationLon' },
        'destinationPortName',
        'destinationPortCode',
        { label: 'Destination Port Long Code', key: 'destinationPortLongCode' },
        { label: 'Distance in Kilometers (KM)', key: 'distanceKm' },
        { label: 'Distance in Miles', key: 'distanceMiles' },
      ];
      tableName = 'sea-distances';
      sortIndex = 'destinationCountryCode';
      actions = ['edit', 'delete', 'add'];
      searchableFields = [
        'id',
        'originCountry',
        'originPortName',
        'originPortLongCode',
        'destinationCountry',
        'destinationPortName',
        'destinationPortLongCode',
      ];
      break;
    case 'airports':
      columns = [
        'code',
        'name',
        'municipality',
        'country',
        'isoRegion',
        { label: 'Latitude', key: 'lat' },
        { label: 'Longitude', key: 'lng' },
      ];
      tableName = 'airport';
      actions = ['add', 'edit', 'delete'];
      sortIndex = 'code';
      searchableFields = ['code', 'name', 'municipality', 'country', 'isoRegion'];
      break;
    case 'help':
      columns = ['id', 'title'];
      actions = ['edit', 'delete', 'add'];
      searchableFields = ['id', 'title'];
      break;
    case 'electricitySources':
      columns = ['id', 'renewable', 'source'];
      actions = ['edit', 'delete', 'add'];
      searchableFields = ['id', 'source'];
      break;
    case 'countries':
      columns = [
        'id',
        'name',
        'countryAlpha2Code',
        'countryAlpha3Code',
        'numericCode',
        'latitude',
        'longitude',
        'capital',
        'defaultAirport',
        'continent',
        'councilRegion',
        { key: 'currencyName', label: 'Currency' },
      ];
      actions = ['edit', 'delete'];
      searchableFields = ['id', 'name', 'countryAlpha2Code', 'countryAlpha3Code', 'continent', 'councilRegion'];
      fetchDataFromOtherTables = ['currencies'];
      break;
    case 'scope3Categories':
      columns = ['id', { label: 'Category Number', key: 'categoryNo' }, 'name', 'description'];
      actions = ['add', 'edit', 'delete'];
      sortIndex = 'id';
      searchableFields = ['id', 'categoryNo', 'name', 'description'];
      break;
    case 'marketFactors':
      columns = [
        'id',
        'source',
        'subentity',
        'renewable',
        'kgco2e',
        'kgco2eWTT',
        'TD',
        'TDWTT',
        'radioactiveWaste',
        'year',
        'outsideOfScopes',
        'factorSource',
        'creationDate',
        'modificationDate',
      ];
      fetchDataFromOtherTables = ['electricitySources', 'addOns', 'usageTypeDetails'];
      actions = ['add', 'edit', 'delete'];
      searchableFields = ['source', 'subentity', 'year', 'id'];
      defaultStateValue =
        Array.isArray(stateVariables?.['usageTypeDetails']) &&
        stateVariables?.['usageTypeDetails'].reduce((acc, ut) => {
          if (['factorprovider', 'originlocation', 'finallocation', 'price'].includes(ut.type)) {
            acc[ut.type] = null;
          }
          return acc;
        }, {});
      tableFilters = [
        { label: 'Subentity', options: 'subentities', name: 'subentity' },
        {
          label: 'Sources',
          options: stateVariables?.['electricitySources']?.map((x) => ({ label: x.source, value: x.id })),
          name: 'source',
        },
        {
          label: 'AddOns',
          options: stateVariables?.['addOns']?.map((x) => ({ label: x.name, value: x.id })),
          name: 'addOns',
        },
      ];
      sortIndex = 'source';
      break;
    case 'subEntities':
      columns = ['id', 'name', 'calc_market', 'description', 'entity', 'metric', 'hideInUI'];
      actions = ['add', 'edit', 'delete', 'recalculate'];
      searchableFields = ['name', 'id'];
      tableName = 'subentity';
      tableFilters = [
        { label: 'Entity', options: 'entities', name: 'entity' },
        { label: 'Metric', options: 'metric', name: 'metric' },
      ];
      break;
    case 'usageTypes':
      const type = table.replace('Type', '');
      columns = type === 'price' ? ['id', 'priceType', 'description'] : ['id', 'name', 'description'];
      actions = ['add', 'edit', 'delete'];
      searchableFields = type === 'price' ? ['priceType', 'id'] : ['name', 'id'];
      sortIndex = 'name';
      tableData = stateVariables?.[type].map((item) => ({ ...item, description: RemoveTags(item.description) }));
      break;
    case 'metrics':
      columns = ['id', 'name', 'description'];
      actions = ['add', 'edit', 'delete'];
      sortIndex = 'id';
      tableData = stateVariables?.['metric'];
      tableName = 'metric';
      searchableFields = ['id', 'name'];
      break;
    case 'facilities':
      columns = [
        'id',
        'name',
        'organisationId',
        'postCode',
        'externalId',
        'country',
        { label: 'Primary Facility', key: 'isPrimary' },
        { label: 'Facility Type', key: 'facilityTypeId' },
      ];
      actions = ['edit', 'delete'];
      tableFilters = [
        { label: 'Organisation', options: 'adminOrgs', name: 'organisationId' },
        { label: 'Facility Type', options: 'facilityTypes', name: 'facilityTypeId' },
      ];
      searchableFields = ['name', 'id'];
      break;
    case 'facilitiesType':
      columns = [
        'id',
        'name',
        'description',
        { label: 'CIBSE Category', key: 'CIBSE_Category' },
        { label: 'Electrical Typical Co2 Benchmark', key: 'Electrical_typical_CO2_benchmark' },
        { label: 'Electricity Typical Benchmark', key: 'Electricity_typical_benchmark' },
        { label: 'Energy Co2e Typical Benchmark', key: 'Energy_C02e_typical_benchmark' },
        { label: 'Extra Intensity Metric', key: 'Extra_Intensity_Metric' },
        { label: 'Fossil-Thermal Co2/m2 benchmark', key: 'Fossil_thermal_CO2_m2_benchmark' },
        { label: 'Fossil-Thermal typical benchmark', key: 'Fossil_thermal_typical_benchmark' },
      ];
      tableName = 'facilityType';
      actions = ['edit', 'delete', 'add'];
      searchableFields = ['name', 'id'];
      break;
    case 'organisationMetrics':
      columns = [
        'id',
        { label: 'organisation', key: 'organisationId' },
        'startDate',
        'endDate',
        'year',
        'metric1',
        'metric2',
        'metric3',
        'originalTurnover',
        'turnover',
        'status',
      ];
      actions = ['edit', 'delete', { action: 'viewOrg', tooltip: 'View Organisation Metric' }];
      tableFilters = [{ label: 'Organisation', options: 'adminOrgs', name: 'organisationId' }];
      searchableFields = ['id', 'year'];
      sortIndex = 'organisationId';
      break;
    case 'benchmark':
      columns = [
        'id',
        { label: 'Organisation', key: 'orgName' },
        { label: 'Industry', key: 'industry' },
        { label: 'Parent Industry', key: 'parent' },
        { label: 'Industry Includes', key: 'industryIncludes' },
        'scope1',
        'scope2',
        'scope3',
        { label: 'Scope 1 & 2 Per m', key: 'scope1and2perm' },
        { label: 'Scope 1 & 2 Per m2', key: 'scope1and2perm2' },
        { label: 'Scope 1 & 2 Per fte', key: 'scope1and2perfte' },
        { label: 'Scope 3 Cat', key: 'scope3cat' },
        { label: 'Scope 3 per m', key: 'scope3perm' },
        'year',
      ];
      tableFilters = [
        {
          label: 'Industry',
          options: _.sortBy(stateVariables?.['industries'], ['name'])?.map((x) => ({ label: x.name, value: x.id })),
          name: 'industry',
        },
        {
          label: 'Organisation',
          options: stateVariables?.['adminOrgs']
            ?.filter((x) => x.accountType === 'Benchmark')
            ?.map((x) => ({ label: x.name, value: x.id })),
          name: 'org',
        },
      ];
      searchableFields = ['orgName', 'id', 'year'];
      fetchDataFromOtherTables = ['industries'];
      actions = ['viewOrg', 'edit', 'delete'];
      break;
    case 'addOns':
      columns = [
        'id',
        'name',
        'creator',
        { label: 'createdDate', key: 'creationDate' },
        { label: 'lastModifiedDate', key: 'modificationDate' },
        'source',
        { label: 'Private', key: 'isPrivate' },
        'owner',
        { label: 'Conversion Factors', key: 'viewData' },
        { label: 'Organisations', key: 'viewData' },
        'stripePriceId',
      ];
      actions = ['edit', 'delete', 'add', { label: 'Add to Organisation', action: 'addTo' }];
      sortIndex = 'id';
      searchableFields = ['name', 'id', 'owner'];
      fetchDataFromOtherTables = ['profile'];
      defaultStateValue = { creator: profile?.id, isPrivate: false, owner: '621784' };
      break;
    case 'usageTypeDetails':
      columns = ['id', 'default', 'displayName', 'subentity', 'type'];
      actions = ['add', 'edit', 'delete'];
      sortIndex = 'id';
      searchableFields = ['id', 'displayName', 'type'];
      fetchDataFromOtherTables = ['usageTypes'];
      break;
    case 'integrationMappings':
      columns = [
        'id',
        { label: 'Entity', key: 'entityId' },
        { label: 'Subentity', key: 'subentityId' },
        'production',
        'price',
        'seat',
        'factorprovider',
        'originlocation',
        'finallocation',
        'vehicle',
        'fuel',
        'load',
        'waste',
      ];
      actions = ['edit', 'add', 'delete'];
      sortIndex = 'entityId';
      tableFilters = [
        {
          label: 'Entity',
          options: stateVariables?.['entities']?.map((x) => ({ label: x.name, value: x.id })),
          name: 'entityId',
        },
        {
          label: 'Subentity',
          options: stateVariables?.['subentities']?.map((x) => ({ label: x.name, value: x.id })),
          name: 'subentityId',
        },
      ];
      fetchDataFromOtherTables = ['usageTypes'];
      searchableFields = ['id', 'entityId', 'subentityId'];
      break;
    case 'currencies':
      columns = [
        'id',
        'name',
        'isoCode',
        'symbol',
        'font:ArialUnicode',
        'unicode:Decimal',
        'unicode:Hex',
        { key: 'hideInUI', label: 'Hide in UI' },
      ];
      actions = ['edit', 'delete', 'add'];
      sortIndex = 'name';
      searchableFields = ['name', 'id', 'isoCode'];
      break;
    case 'stripe':
      columns = [{ key: 'id', label: 'Organisation' }, { key: 'customerID', label: 'Customer Id' }, 'type'];
      sortIndex = 'id';
      searchableFields = ['id', 'customerID'];
      tableFilters = [{ label: 'Organisation', options: 'adminOrgs', name: 'org' }];
      break;
    default:
      break;
  }

  if (canEditAdminPortal && actions.length) {
    // Add the Actions Column, that allows the user to edit or delete the entry
    columns.push('actions');
  }

  return {
    columns,
    columnsToHide,
    actions,
    sortIndex,
    tableName,
    fetchDataFromOtherTables,
    tableFilters,
    tableData,
    searchableFields,
    defaultStateValue,
  };
}
